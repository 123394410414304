import {v3GetCookie, v3SetCookie, v3DeleteCookie} from "../../shared/helpers";

export const AUTH_TOKEN_KEY = 'authToken';
export const AUTH_USER_KEY = 'authUser';
export const AUTH_ORGANIZATION_KEY = 'authOrganization';
export const SU_ORGANIZATION_KEY = 'suOrganization';
export const SU_SELECT_ORGANIZATION_TREE = "suSelectOrganizationTree";
export const AUTH_ORGANIZATION_TREE = "authSelectOrganizationTree";

export const ORGANIZATION_ID = 1

export const login = data => {
    // console.log(data)
    // return
    const now = new Date();
    const item = {
        key: data.access_token,
        expiry: now.getTime() + 30 * 60 * 1000,
    }

    localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(item));
    v3SetCookie('isAuthenticated', 'yes');

    localStorage.setItem(AUTH_USER_KEY, JSON.stringify(data.user));
    // if(data.programCount)
    // {
    //   data.user.organization.programCount = data.programCount
    // }
    localStorage.setItem(AUTH_ORGANIZATION_KEY, JSON.stringify(data.user.organization));
}

export const logout = (e) => {
    // alert("Hello")
    if( window.confirm( 'Are you sure to log out?') )    {
        flushUserSession();
        window.location = '/';
    }
    e.preventDefault();
}

export const flushUserSession = () => {
    localStorage.removeItem(AUTH_USER_KEY);
    localStorage.removeItem(AUTH_TOKEN_KEY);
    v3DeleteCookie('isAuthenticated');
    localStorage.removeItem(AUTH_ORGANIZATION_KEY);
    // localStorage.removeItem(SU_ORGANIZATION_KEY);
    localStorage.removeItem(SU_SELECT_ORGANIZATION_TREE);
    localStorage.removeItem(AUTH_ORGANIZATION_TREE);
}

export const isAuthenticated = () => {
    // flushUserSession();
    const itemStr = localStorage.getItem(AUTH_TOKEN_KEY);
    const cookieCheck = v3GetCookie('isAuthenticated');

    if (cookieCheck && itemStr) {
        let item = null;
        try {
           item = JSON.parse(itemStr)
        } catch (e) {
            return false;
        }
        const now = new Date()
        if (now.getTime() > item.expiry) {
            flushUserSession();
        } else {
            const now = new Date();
            const refreshItem = {
                key: item.key,
                expiry: now.getTime() + 30 * 60 * 1000,
            }
            localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(refreshItem));

            // console.log(getAuthUser())
            return true;
        }
    }
    return false;
}

export const isVerified = () => {
    var authUser = getAuthUser();
    if ( authUser ) {
        return authUser.email_verified_at !== null;
    }
    return false;
}

export const getToken = () => {
    const itemStr = localStorage.getItem(AUTH_TOKEN_KEY);
    if (itemStr) {
        let item = null;
        try {
            item = JSON.parse(itemStr)
        } catch (e) {
            return null;
        }
        return item.key;
    }
    return null;
}

export const getBearer = () => {
    // console.log(getAuthUser())
    const AuthToken = getToken();
    // console.log(AuthToken)
    return AuthToken ? 'Bearer ' + AuthToken : null
}

export const getAuthUser = () => {
    try {
        const authUser = JSON.parse(localStorage.getItem(AUTH_USER_KEY))
        return authUser
    } catch (e) {
        return null;
    }
}

export const getOrganization = () => {
    // flushUserSession();
    return JSON.parse(localStorage.getItem(AUTH_ORGANIZATION_KEY));
}

//The organization selection by Super User

// export const getSuOrganization = () => {
//   // flushUserSession();
//   return JSON.parse(localStorage.getItem(SU_ORGANIZATION_KEY));
// }

export const getAuthUserFullname = () => {
    const user = getAuthUser();
    // console.log(isVerified())
    if( user ) return `${user.first_name} ${user.last_name}`
}

export const asyncLocalStorage = {
    setItem: async function (key, value) {
        await null;
        return localStorage.setItem(key, value);
    },
    getItem: async function (key) {
        await null;
        return localStorage.getItem(key);
    }
};

// export const setSuOrganization = (organization) => {
//   return localStorage.setItem(SU_ORGANIZATION_KEY, JSON.stringify(organization));
// };

export const setOrganization = (organization) => {
  return localStorage.setItem(AUTH_ORGANIZATION_KEY, JSON.stringify(organization));
}