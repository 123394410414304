import React,{useEffect,useState} from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import {isEmpty} from '@/shared/helpers'
import {useParams} from "react-router-dom";
import { getAllPrograms } from '@/shared/apiHelper.jsx';
import BillingInformationCard from "./components/BillingInformationIndex.jsx";

const BillingInformation = () => {
  const [defaultPrograms, setDefaultPrograms] = useState([]);
  const [wait, setWait] = useState(false);
  let {programId} = useParams();

  useEffect(() => {
    if ( isEmpty(defaultPrograms) && wait === false ) {
      setWait(true);
      getAllPrograms("minimal=true&limit=99999999&programId=" + programId)
      .then( response => {
        const data = response?.data ? response.data : [];
        const result = data.map(x => x.account_holder_id)
        setDefaultPrograms(result);
        setWait(false);
      })
    }
  }, [programId])

  if (isEmpty(defaultPrograms) || wait) {
    return <p>Loading...</p>;
  }
  return (
    <Container className="dashboard">
      <h3 className="page-title">Billing Information Report</h3>
      <Row>
        <BillingInformationCard programs={defaultPrograms} />
      </Row>
    </Container>
  );
};

export default BillingInformation;
