import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col} from 'reactstrap';
import BillingInformationDataTable from './BillingInformationDataTable';
import {isEmpty} from '@/shared/helpers'
import {withRouter,useParams} from "react-router-dom";
import {connect} from "react-redux";
import { getAllPrograms } from '@/shared/apiHelper.jsx';

const BillingInformationIndex = ({programs}) => {

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <BillingInformationDataTable programs={programs} />
        </CardBody>
      </Card>
    </Col>
  )
}

export default withRouter(connect((state) => ({
  organization: state.organization
}))(BillingInformationIndex));

