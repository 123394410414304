import { Link } from "react-router-dom";
import { toCurrency } from "@/shared/helpers";

export const TABLE_COLUMNS = [
  {
    Header: "Program Name",
    accessor: "name",
    Cell:({row,value})=>{
      return <Link to={`/program/view/${row?.original?.id}`}  >{value}</Link>
    },
    className: 'frozenColumn',
    noAlignRight: true
  },
  {
    Header: "Deposit Fee",
    accessor: "deposit_fee",
    Cell: ({ row, value }) => {
      return toCurrency(value);
    },
  },
  {
    Header: "Fixed Fee",
    accessor: "fixed_fee",
    Cell: ({ row, value }) => {
      return toCurrency(value);
    },
  },
  {
    Header: "Convenience Fee",
    accessor: "convenience_fee",
    Cell: ({ row, value }) => {
      return toCurrency(value);
    },
  },
  {
    Header: "Monthly Usage Fee	",
    accessor: "monthly_usage_fee",
    Cell: ({ row, value }) => {
      return toCurrency(value);
    },
  },
  {
    Header: "Factor For Points Valuation",
    accessor: "factor_valuation",
  },
  {
    Header: "Reserve Percentage",
    accessor: "reserve_percentage",
    Cell: ({ row, value }) => {
      return <span>{value}%</span>;
    },
  },
  {
    Header: "Bill to Parent Program?",
    accessor: "bill_parent_program",
  },
  {
    Header: "Administrative Fee: Calculation",
    accessor: "administrative_fee_calculation",
  },
  {
    Header: "Administrative Fee: Fee Amount",
    accessor: "administrative_fee",
  },
  {
    Header: "Administrative Fee: Custom Factor",
    accessor: "administrative_fee_factor",
    Cell: ({ row, value }) => {
      return toCurrency(value);
    },
  },
  {
    Header: "Accounts Receivable Email",
    accessor: "accounts_receivable_email",
    Cell: ({ row, value }) => {
      return value != 0 ? <span>{value}</span> : "";
    },
  },
  {
    Header: "BCC Email List	",
    accessor: "bcc_email_list",
    Cell: ({ row, value }) => {
      return value != 0 ? <span>{value}</span> : "";
    },
  },
  {
    Header: "CC Email List",
    accessor: "cc_email_list",
    Cell: ({ row, value }) => {
      return value != 0 ? <span>{value}</span> : "";
    },
  },
];
