import React, { useEffect, useMemo, useState } from "react";
import { Col, Row, Button, Table, Input } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import getUserPointBalance from "../../../service/getUserBalances";
import {
  useExpanded,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
  useRowSelect,
} from "react-table";
import ReactTablePagination from "@/shared/components/table/components/ReactTablePagination";
import {
  reducer,
  useEffectToDispatch,
  fetchApiData,
  initialState,
  TableFilter,
} from "@/shared/apiTableHelper";
import { useDispatch, sendFlashMessage } from "@/shared/components/flash";
import SortIcon from "mdi-react/SortIcon";
import SortAscendingIcon from "mdi-react/SortAscendingIcon";
import SortDescendingIcon from "mdi-react/SortDescendingIcon";
import { toCurrency, toPoints } from "@/shared/helpers";
import { IndeterminateCheckbox } from "@/shared/components/form/IndeterminateCheckbox";

const queryClient = new QueryClient();

const ProgramViewUserChangeLogs = ({
  organization,
  programs,
  userPrograms,
  user,
}) => {
  const [trigger, setTrigger] = useState(Math.floor(Date.now() / 1000));
  const [useFilter, setUseFilter] = useState({ programs: programs });
  const [filter, setFilter] = useState({ programs: programs });
  const [userBalance, setPointsBalance] = useState(0);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [reclaimPointsSum, setReclaimPointsSum] = useState(0);
  const [reasonNotesValues, setReasonNotesValues] = useState({});

  const dispatcher = useDispatch();

  const handleInputChange = (key, value) => { 
    setReasonNotesValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  let final_columns = useMemo(
    () => [
      ...RECLAIM_POINT_COLUMNS,
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div className="d-flex" style={{gap:"10px"}}><IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />{" "}<span>Reclaim?</span></div>
        ),
        Cell: ({ row }) => (
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        ),
        width:100,
      },
      {
        Header: "Reason / Notes",
        accessor: "input",
        Cell: ({ row }) => {
          return (
            <>
              {" "}
              <Input
                type="text"
                // value={reasonNotesValues[row.original?.key] || ''}
                onChange={(e)=>handleInputChange(row.original.key,e.target.value)}
              />
            </>
          );
        },
      },
    ],
    [RECLAIM_POINT_COLUMNS]
  );


  useEffect(() => {
    if (organization?.id && userPrograms.length > 0 && user?.id) {
      getUserPointBalance(organization.id, userPrograms[0]?.id, user.id).then(
        (point) => {
          setPointsBalance(point.amount);
        }
      );
    }
  }, [userPrograms, organization, user]);
  const [
    {
      queryPageIndex,
      queryPageSize,
      totalCount,
      queryPageFilter,
      queryPageSortBy,
      queryTrigger,
    },
    dispatch,
  ] = React.useReducer(reducer, initialState);

  const apiUrl = `/organization/${organization.id}/user/${user.id}/reclaim-items`;
  const { isLoading, error, data, isSuccess, refetch } = useQuery(
    [
      "",
      queryPageIndex,
      queryPageSize,
      queryPageFilter,
      queryPageSortBy,
      queryTrigger,
    ],
    () =>
      fetchApiData({
        url: apiUrl,
        page: queryPageIndex,
        size: queryPageSize,
        filter: queryPageFilter,
        sortby: queryPageSortBy,
        trigger: queryTrigger,
      }),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  const totalPageCount = Math.ceil(totalCount / queryPageSize);

  const tableInstance = useTable(
    {
      columns: final_columns,
      data: useMemo(() => (data ? data?.results : []), [data]),
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
      },
      manualPagination: true,
      pageCount: data ? totalPageCount : 0,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  useEffectToDispatch(dispatch, {
    pageIndex,
    pageSize,
    gotoPage,
    sortBy,
    filter,
    data,
    useFilter,
    trigger,
  });
  const manualPageSize = [];

  useEffect(() => {
    if (selectedFlatRows) {
      const allKeys = selectedFlatRows.map((row) => row.original.key);
      setSelectedRows(allKeys);
    }
  }, [selectedFlatRows]);

  useEffect(() => {
    let sum = 0;
    data?.results?.forEach((item) => {
      if (selectedRows.includes(item.key)) {
        sum += parseFloat(item.amount);
      }
    });
    sum = parseFloat(sum.toFixed(2));
    if (Number.isInteger(sum)) {
      sum += ".00";
    }
    setReclaimPointsSum(sum);
    if (userBalance < sum) {
      dispatcher(
        sendFlashMessage(
          "Reclaim is not possible as it exceeds the current balance",
          "alert-danger"
        )
      );
    }
  }, [selectedRows, data]);

  const handleReclaimPoints = () => {
    selectedRows?.map(value => {
        const item = data?.results.filter(obj => {
            return obj.key === value;
        });
        
        let itemProgramId = item[0]['program_id'];

        if (reasonNotesValues[value]){
            reclaim(value, reasonNotesValues[value], itemProgramId);
        }
    })
};

  const reclaim = async (postingId, notes, itemProgramId) => {
    try {
        const response = await axios.post(`/organization/` + organization.id + `/user/reclaim`, {
            userId: user.id,
            program_account_holder_id: itemProgramId,
            postingId: postingId,
            notes: notes,
        });
        if (response?.status === 200) {
            window.location.reload();
        }
    } catch (e) {
    }
}

  if (!programs || !organization?.id || isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Row>
        <Col md={12}>
          <div className="action-panel">
            <Row className="mx-0">
              <Col>
                <TableFilter
                  filter={filter}
                  setFilter={setFilter}
                  setUseFilter={setUseFilter}
                  config={{
                    keyword: false,
                    programs: true,
                  }}
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col md="8">
              <Button
                size="sm"
                color="primary"
                disabled={
                  parseFloat(userBalance) < parseFloat(reclaimPointsSum) ||
                  parseFloat(reclaimPointsSum) <= 0
                }
                onClick={handleReclaimPoints}
              >
                Reclaim Points
              </Button>
            </Col>
            <Col span={11}></Col>
            <Col span={5}>
              Balance{" "}
              <span
                style={{
                  color:
                    userBalance > 0
                      ? "green"
                      : userBalance < 0
                      ? "red"
                      : "grey",
                }}
              >
                {" "}
                ${userBalance}{" "}
              </span>
              Reclaim Points Sum{" "}
              <span
                style={{
                  color:
                    parseFloat(reclaimPointsSum) > parseFloat(userBalance)
                      ? "red"
                      : "inherit",
                }}
              >
                {" "}
                ${reclaimPointsSum}{" "}
              </span>
            </Col>
          </Row>
          <Table striped borderless size="md" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {column.isSorted ? <Sorting column={column} /> : ""}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>
                          {cell.column.Header == "Reason / Notes" ? (
                            <>
                              {cell.render("Cell")}
                              {!reasonNotesValues[row.original.key] &&
                                selectedRows?.includes(row.original.key) && (
                                  <span style={{ color: "red" }}>
                                    Please enter a value for the Reason/Notes
                                    field.
                                  </span>
                                )}
                            </>
                          ) : (
                            cell.render("Cell")
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <p>&nbsp;</p>
          {rows.length > 0 && (
            <>
              <ReactTablePagination
                page={page}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageOptions={pageOptions}
                pageSize={pageSize}
                pageIndex={pageIndex}
                pageCount={pageCount}
                setPageSize={setPageSize}
                manualPageSize={manualPageSize}
                dataLength={totalCount}
              />
              <div className="pagination justify-content-end mt-2">
                <span>
                  Go to page:{" "}
                  <input
                    type="number"
                    value={pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                    style={{ width: "100px" }}
                  />
                </span>{" "}
                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const Sorting = ({ column }) => (
  <span className="react-table__column-header sortable">
    {column.isSortedDesc === undefined ? (
      <SortIcon />
    ) : (
      <span>
        {column.isSortedDesc ? <SortAscendingIcon /> : <SortDescendingIcon />}
      </span>
    )}
  </span>
);

export default ProgramViewUserChangeLogs;

const RECLAIM_POINT_COLUMNS = [
  {
    Header: "Program Name",
    accessor: "program_name",
    width:120
  },
  {
    Header: "Award Value",
    accessor: "amount",
    Cell: ({ row, value }) => toCurrency(value),
    width:120
  },
  {
    Header: "Date Awarded",
    accessor: "awarded",
    Cell: ({ row, value }) => {
      return <span>{new Date(value).toLocaleDateString("en-us")}</span>;
    },
  },
  { Header: "Event", accessor: "event_name" },
  { Header: "Award Credit", accessor: "award_credit" },
  {
    Header: " Expiration Date",
    accessor: "expiration",
    Cell: ({ row, value }) => {
      return <span>{new Date(value).toLocaleDateString("en-us")}</span>;
    },
  },
];
