import React, { useMemo, useState, useEffect } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useExpanded,
  useResizeColumns,
  useFlexLayout,
} from "react-table";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import SortIcon from "mdi-react/SortIcon";
import SortAscendingIcon from "mdi-react/SortAscendingIcon";
import { connect } from "react-redux";
import SortDescendingIcon from "mdi-react/SortDescendingIcon";
import { useParams, withRouter, Link } from "react-router-dom";
import { ThemeProps, RTLProps } from "@/shared/prop-types/ReducerProps";
import ReactTablePagination from "@/shared/components/table/components/ReactTablePagination";
import {
  reducer,
  useEffectToDispatch,
  fetchApiData,
  initialState,
  TableFilter,
} from "@/shared/apiTableHelper";
import getUserPointBalance from "../../../../service/getUserBalances";
import { fetchRoles } from "../../../../shared/apiHelper";
import { Modal, Row, Col, ButtonToolbar, Button, Container } from "reactstrap";
import {
  useDispatch,
  flashSuccess,
  flashError,
} from "@/shared/components/flash";
import axios from "axios";
import getUserStatusList from "@/service/getUserStatuses";

const USERS_COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
    width: 180,
  },
  {
    Header: "Status",
    accessor: "status",
    width: 70,
  },
];

const strUserProgramRoles = (user) => {
  let pRoles = [];
  if (user?.roles) {
    for (var i in user.roles) {
      pRoles.push(user.roles[i].name);
    }
  }
  return pRoles.join(", ") || "--";
};

const queryClient = new QueryClient();

const DataTable = ({ program, organization, auth, user }) => {
  const dispatchs = useDispatch();
  const [roles, setRoles] = useState([]);
  const [filter, setFilter] = useState({ keyword: "", role_id: "" });
  const [useFilter, setUseFilter] = useState(false);
  const [trigger, setTrigger] = useState(Math.floor(Date.now() / 1000));
  const [loading, setLoading] = useState(false);

  const onClickUserMerge = (fromUser, toUser) => {
    let data = {};
    data.to_program_id = program.id;
    data.from_program_id = program.id;
    data.from_user_id = fromUser.id;
    data.to_user_id = toUser.id;
    axios
      .post(
        `/organization/${program.organization_id}/program/${program.id}/user/${auth.id}/merge-user`,
        data
      )
      .then((res) => {
        if (res.status === 200) {
          flashSuccess(dispatchs, "User Merge successfully!");
          setTimeout(() => {
            window.location.href = `/program/${program.id}/user/view/${res.data.data.id}`;
          }, 2000);
        }
      })
      .catch((err) => {
        console.log("error", err);
        flashError(dispatchs, err.response.data);
      });
  };

  const RenderActions = ({ row }) => {
    return (
      <>
        <span
          onClick={() => onClickUserMerge(user, row.original)}
          className="text-danger"
          style={{ cursor: "pointer" }}
        >
          Merge
        </span>
        {" | "}
        <Link
          className="link a"
          to={`/program/${program.id}/user/view/${row.original.id}`}
        >
          View
        </Link>
      </>
    );
  };

  const strShowUserStatus = (user) => {
    return user?.status?.status ? <span>{user.status.status}</span> : "unknown";
  };

  let user_columns = [
    ...USERS_COLUMNS,
    ...[
      {
        Header: "Program Roles",
        accessor: "role_id",
        Cell: ({ row, value }) => {
          return strUserProgramRoles(row.original);
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => <RenderActions row={row} />,
      },
    ],
  ];
  user_columns.forEach((column, i) => {
    if (column.Header === "Status") {
      user_columns[i].Cell = ({ row, value }) => {
        return strShowUserStatus(row.original);
      };
    }
  });
  let columns = useMemo(() => user_columns, []);

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400,
    }),
    []
  );

  const [
    {
      queryPageIndex,
      queryPageSize,
      totalCount,
      queryPageFilter,
      queryPageSortBy,
      queryTrigger,
    },
    dispatch,
  ] = React.useReducer(reducer, initialState);

  const apiUrl = `/organization/${program.organization_id}/program/${program.id}/user`;

  const { isLoading, error, data, isSuccess } = useQuery(
    [
      "users",
      queryPageIndex,
      queryPageSize,
      queryPageFilter,
      queryPageSortBy,
      queryTrigger,
    ],
    () =>
      fetchApiData({
        url: apiUrl,
        page: queryPageIndex,
        size: queryPageSize,
        filter,
        sortby: queryPageSortBy,
        trigger: queryTrigger,
      }),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (program) {
      getRoles();
    }
  }, [program]);

  const getRoles = () => {
    setLoading(true);
    fetchRoles(organization.id, true)
      .then((data) => {
        const formattedRoles = data.map((role) => ({
          value: role.id,
          label: role.name,
        }));
        setRoles(formattedRoles);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
        setLoading(false);
      });
  };

  const totalPageCount = Math.ceil(totalCount / queryPageSize);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
    preFilteredRows,
  } = useTable(
    {
      columns,
      data: data ? data.results : [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
      },
      manualPagination: true,
      pageCount: data ? totalPageCount : 0,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
      defaultColumn,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useResizeColumns,
    useFlexLayout
  );

  const manualPageSize = [];

  useEffectToDispatch(dispatch, {
    pageIndex,
    pageSize,
    gotoPage,
    sortBy,
    filter,
    data,
    useFilter,
    trigger,
  });

  useEffect(() => {
    if (organization.id) {
      getRoles();
    }
  }, [organization.id]);

  if (error) {
    return <p>Error: {JSON.stringify(error)}</p>;
  }

  if (isLoading || !data || loading) {
    return <p>Loading...</p>;
  }

  if (isSuccess)
    return (
      <>
        <div className="table react-table">
          <form className="form form--horizontal">
            <div className="form__form-group pb-4">
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-9 col-lg-9">
                      <TableFilter
                        config={{ keyword: true, role: true, label: "users" }}
                        filter={filter}
                        setFilter={setFilter}
                        setUseFilter={setUseFilter}
                        roles={roles.map((role) => ({
                          id: role.value,
                          name: role.label,
                        }))}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {column.isSorted ? <Sorting column={column} /> : ""}
                      <div
                        {...column.getResizerProps()}
                        className={`resizer ${
                          column.isResizing ? "isResizing" : ""
                        }`}
                      />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="table table--bordered" {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                const subCount = (row.id.match(/\./g) || []).length;
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      const paddingLeft = subCount * 20;
                      return (
                        <td {...cell.getCellProps()}>
                          <span
                            style={
                              cell.column.Header === "#"
                                ? { paddingLeft: `${paddingLeft}px` }
                                : null
                            }
                          >
                            {cell.render("Cell")}
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {rows.length > 0 && (
          <>
            <ReactTablePagination
              page={page}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageSize={pageSize}
              pageIndex={pageIndex}
              pageCount={pageCount}
              setPageSize={setPageSize}
              manualPageSize={manualPageSize}
              dataLength={totalCount}
            />
            <div className="pagination justify-content-end mt-2">
              <span>
                Go to page:{" "}
                <input
                  type="number"
                  value={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  style={{ width: "100px" }}
                />
              </span>{" "}
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </>
        )}
      </>
    );
};

const Sorting = ({ column }) => (
  <span className="react-table__column-header sortable">
    {column.isSortedDesc === undefined ? (
      <SortIcon />
    ) : (
      <span>
        {column.isSortedDesc ? <SortAscendingIcon /> : <SortDescendingIcon />}
      </span>
    )}
  </span>
);

const TableWrapper = ({ program, organization, auth, data }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <DataTable
        program={program}
        organization={organization}
        user={data}
        auth={auth}
      />
    </QueryClientProvider>
  );
};

const MergeProgramUserModal = ({
  organization,
  program,
  auth,
  isOpen,
  setOpen,
  toggle,
  theme,
  rtl,
  data,
}) => {
  let { programId, userId } = useParams();
  const [pointsBalance, setPointsBalance] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (organization?.id && programId && userId) {
      setLoading(true);
      getUserPointBalance(organization.id, programId, userId)
        .then((point) => {
          setPointsBalance(point);
          setLoading(false);
        })
        .catch((err) => {
          console.log("err", err.message);
        });
    }
  }, [organization, programId, userId]);

  useEffect(() => {
    if (data?.id && organization?.id) {
      setLoading(true);
      getUserStatusList(organization.id)
        .then((result) => {
          const userStatusData = result.filter(
            (s) => s.id === data.user_status_id
          );
          const [id] = userStatusData;
          setUserStatus(id);
          setLoading(false);
        })
        .catch((err) => {
          console.log("err", err.message);
          setLoading(false);
        });
    }
  }, [data]);

  return (
    <Modal
      className={`modal-program modal-lg ${theme.className} ${rtl.direction}-support`}
      isOpen={isOpen}
      toggle={toggle}
    >
      <Row className="w100">
        <Col md="6" lg="6" xl="6">
          <h3 className="mb-4">Merge User</h3>
        </Col>
        <Col md="6" lg="6" xl="6" className="text-right">
          <ButtonToolbar className="modal__footer flex justify-content-right w100">
            <Button outline color="primary" className="mr-3" onClick={toggle}>
              Close
            </Button>{" "}
            <Button type="submit" className="btn btn-primary" color="#ffffff">
              Save
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
      {!loading && data ? (
        <Container>
          <p
            style={{ fontWeight: "bold" }}
            className="mt-3 mb-3 p-2 bordered rounded"
          >
            This account will not survive the merge process. Be certain this is
            the account you wish to merge before continuing.
          </p>
          <Row>
            <Col>
              {" "}
              <h5
                className="mb-4"
                style={{ fontSize: "20px", fontWeight: "bold" }}
              >
                Participant Account Selected for Merge
              </h5>
            </Col>
            <Col>
              <h6
                className="mb-4"
                style={{ fontSize: "20px", fontWeight: "medium" }}
              >
                Balance
              </h6>
            </Col>
          </Row>
          <Row>
            <Col md="6" lg="6" sm="6">
              <Row>
                <Col md="4" lg="4" xl="4" sm="4" className="label">
                  <p>First Name :</p>
                </Col>
                <Col md="6" lg="6" xl="6" sm="6">
                  <p>{data.first_name}</p>
                </Col>
              </Row>
              <Row>
                <Col md="4" lg="4" xl="4" sm="4" className="label">
                  <p>Last Name :</p>
                </Col>
                <Col md="6" lg="6" xl="6" sm="6">
                  <p>{data.last_name}</p>
                </Col>
              </Row>
              <Row>
                <Col md="4" lg="4" xl="4" sm="4" className="label">
                  <p>Roles :</p>
                </Col>
                <Col md="6" lg="6" xl="6" sm="6">
                  <p>{data.roles}</p>
                </Col>
              </Row>
              <Row>
                <Col md="4" lg="4" xl="4" sm="4" className="label">
                  <p>Email :</p>
                </Col>
                <Col md="6" lg="6" xl="6" sm="6">
                  <p>{data.email}</p>
                </Col>
              </Row>
              <Row>
                <Col md="4" lg="4" xl="4" sm="4" className="label">
                  <p>Unit Number :</p>
                </Col>
                <Col md="6" lg="6" xl="6" sm="6">
                  <p>{data?.unitNumber?.name}</p>
                </Col>
              </Row>
              <Row>
                <Col md="4" lg="4" xl="4" sm="4" className="label">
                  <p>Status :</p>
                </Col>
                <Col md="6" lg="6" xl="6" sm="6">
                  <p>{userStatus?.status}</p>
                </Col>
              </Row>
            </Col>
            <Col md="6" lg="6" sm="6">
              <Row>
                <Col md="4" lg="4" xl="4" sm="4" className="label">
                  <p>Points Earned :</p>
                </Col>
                <Col md="6" lg="6" xl="6" sm="6">
                  <p>{pointsBalance?.points * pointsBalance?.factor}</p>
                </Col>
              </Row>
              <Row>
                <Col md="4" lg="4" xl="4" sm="4" className="label">
                  <p>Points Redeemed :</p>
                </Col>
                <Col md="6" lg="6" xl="6" sm="6">
                  <p>
                    {pointsBalance?.redeemedBalance * pointsBalance?.factor}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="4" lg="4" xl="4" sm="4" className="label">
                  <p>Points Balance :</p>
                </Col>
                <Col md="6" lg="6" xl="6" sm="6">
                  <p>{pointsBalance?.amount * pointsBalance?.factor}</p>
                </Col>
              </Row>
              <Row>
                <Col md="4" lg="4" xl="4" sm="4" className="label">
                  <p>Peer Allocation Balance :</p>
                </Col>
                <Col md="6" lg="6" xl="6" sm="6">
                  <p>{pointsBalance?.peerBalance * pointsBalance?.factor}</p>
                </Col>
              </Row>
              <Row>
                <Col md="4" lg="4" xl="4" sm="4" className="label">
                  <p>Points Expired :</p>
                </Col>
                <Col md="6" lg="6" xl="6" sm="6">
                  <p>{pointsBalance?.expiredBalance * pointsBalance?.factor}</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <p
                style={{ fontWeight: "bold" }}
                className="mt-3 p-2 bordered rounded"
              >
                Select the account to merge into. The account selected account
                below should be the account that you wish to survive the merge
                process. All award activity will be transferred to the account
                selected below and the account above will be deleted.
              </p>
              <p
                style={{ fontWeight: "bold" }}
                className="mt-3 p-2 bordered rounded"
              >
                Merging will result in the loss of any goal progress accumulated
                on the above account.
              </p>
            </Col>
          </Row>
          <div className="mt-3">
            <TableWrapper
              organization={organization}
              program={program}
              data={data}
              auth={auth}
            />
          </div>
        </Container>
      ) : (
        <p>Loading...</p>
      )}
    </Modal>
  );
};
MergeProgramUserModal.propTypes = {
  theme: ThemeProps.isRequired,
  rtl: RTLProps.isRequired,
};

export default withRouter(
  connect((state) => ({
    theme: state.theme,
    rtl: state.rtl,
    auth: state.auth,
  }))(MergeProgramUserModal)
);
