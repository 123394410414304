import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FullCycleAndBreakageTable from "./components/FullCycleAndBreakageTable.jsx";
import { isEmpty } from "@/shared/helpers";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { getAllPrograms } from "@/shared/apiHelper.jsx";

const FullCycleAndBreakage = () => {
  const [defaultPrograms, setDefaultPrograms] = useState([]);
  const [wait, setWait] = useState(false);
  let { programId } = useParams();

  useEffect(() => {
    if (isEmpty(defaultPrograms) && wait === false) {
      setWait(true);
      getAllPrograms("minimal=true&limit=99999999&programId=" + programId).then(
        (response) => {
          const data = response?.data ? response.data : [];
          const result = data.map((x) => x.account_holder_id);
          setDefaultPrograms(result);
          setWait(false);
        }
      );
    }
  }, [programId]);

  if (isEmpty(defaultPrograms) || wait) {
    return <p>Loading...</p>;
  }

  return (
    <Container className="dashboard">
      <h3 className="page-title">Full Cycle And Breakage Report</h3>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <FullCycleAndBreakageTable programs={defaultPrograms} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default FullCycleAndBreakage;
