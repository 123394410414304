import { toCurrency } from "@/shared/helpers";


export const COLUMNS = [
  {
    Header: "Program",
    accessor: "name",
  },
  {
    Header: "Year",
    accessor: "",
  },
  {
    Header: "Awards",
    accessor: "awarded_points",

    Cell: ({ row, value }) => {
      return toCurrency(value);
    },
  },
  {
    Header: "Expired",
    accessor: "expired_points",

    Cell: ({ row, value }) => {
      return toCurrency(value);
    },
  },
  {
    Header: "Deactivated",
    accessor: "deactivated_points",

    Cell: ({ row, value }) => {
      return toCurrency(value);
    },
  },
  {
    Header: "Total Expired Points",
    accessor: "total_expired_points",

    Cell: ({ row, value }) => {
      return toCurrency(value);
    },
  },
  {
    Header: "Percent Expirations",
    accessor: "percent_expirations",
    Cell: ({ row, value }) => <span>{value}%</span>,
  },
];
