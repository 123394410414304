import React, { useState, useEffect, useMemo } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useResizeColumns,
  useExpanded,
} from "react-table";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { COLUMNS } from "./columns";
import { Col, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { clone } from "lodash";
import FullCycleAndBreakageFilter from "./FullCycleAndBreakageFilter";
import {
  reducer,
  useEffectToDispatch,
  fetchApiData,
  fetchApiDataExport,
  initialState,
} from "@/shared/apiTableHelper";
import { getFirstDay } from "@/shared/helpers";

const queryClient = new QueryClient();

const DataTable = ({ organization, programs }) => {
  const defaultFrom = getFirstDay();
  const [filter, setFilter] = useState({
    programs: programs,
    awardLevels: [],
    year: new Date().getFullYear(),
  });
  const [useFilter, setUseFilter] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [exportData, setExportData] = useState([]);
  const [exportHeaders, setExportHeaders] = useState([]);
  const [exportToCsv, setExportToCsv] = useState(false);
  const exportLink = React.createRef();

  useEffect(() => {
    if (exportToCsv) {
      if (exportLink.current) {
        setExportToCsv(false);
        exportLink.current.link.click();
      }
    }
  }, [exportLink]);

  useEffect(() => {
    setFilter({
      programs: programs,
      awardLevels: [],
      year: new Date().getFullYear(),
    });
  }, [programs]);

  const genrateAnualCsvData = (data, header) => {
    const annualheaderData = header.map((row) => row.key);
    const annualData = data.map((row) => [
      row.name,
      row.year,
      row.awarded_points,
      row.expired_points,
      row.deactivated_points,
      row.total_expired_points,
      row.percent_expirations,
    ]);
    return [["Annual Breakage Report"], annualheaderData, ...annualData, []];
  };

  const genrateQuartelyData = (data, header) => {
    const quartelyHeaderData = header.map((row) => row.key);
    const quarterlyData = data.map((row) => [
      row.name,
      row.year,
      row.awarded_points,
      row.expired_points,
      row.deactivated_points,
      row.total_expired_points,
      row.percent_expirations,
    ]);
    return [["Quartely Breakage Report"], quartelyHeaderData, ...quarterlyData];
  };

  const download = async (filterValues) => {
    let tmpFilter = clone(filterValues);
    tmpFilter.exportToCsv = 1;
    tmpFilter.server = "program";

    const response = await fetchApiDataExport({
      url: apiUrl,
      filter: tmpFilter,
      sortby: queryPageSortBy,
      trigger: queryTrigger,
    });

    let csv1 = genrateAnualCsvData(
      response.results["Annual Breakage Report"],
      response?.headers?.["Annual Breakage Report"]
    );
    let csv2 = genrateQuartelyData(
      response?.results?.["Quarterly Breakage Report"],
      response?.headers?.["Quarterly Breakage Report"]
    );

    const combinedData = [...csv1, ...csv2];
    const csvContent = combinedData
      .map((row) =>
        row
          .map((val) => {
            if (typeof val === "string" && val.includes(",")) {
              return val.replace(/,/g, "");
            }
            return val;
          })
          .join(",")
      )
      .join("\n");

    setExportData(csvContent);
    setExportToCsv(true);
  };

  let program_columns = [...COLUMNS];
  let columns = useMemo(() => program_columns, []);

  const [
    {
      queryPageIndex,
      queryPageSize,
      totalCount,
      queryPageFilter,
      queryPageSortBy,
      queryTrigger,
    },
    dispatch,
  ] = React.useReducer(reducer, initialState);

  const apiUrl = `/organization/${organization.id}/report/breakage`;

  const { isLoading, error, data, isSuccess } = useQuery(
    [
      "fullCycleAndBreakage",
      apiUrl,
      queryPageIndex,
      queryPageSize,
      queryPageFilter,
      queryPageSortBy,
      queryTrigger,
    ],
    () =>
      fetchApiData({
        url: apiUrl,
        page: queryPageIndex,
        size: queryPageSize,
        filter,
        trigger: queryTrigger,
      }),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  const totalPageCount = Math.ceil(totalCount / queryPageSize);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data: data ? data?.results?.yearly : [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
      },
      manualPagination: true, // Tell the usePagination
      pageCount: data ? totalPageCount : null,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
      disableResizing: true,
      autoResetHiddenColumns: false,
      striped: true,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useResizeColumns
  );

  useEffectToDispatch(dispatch, {
    pageIndex,
    pageSize,
    gotoPage,
    sortBy,
    filter,
    data,
    useFilter,
    trigger,
  });

  if (error) {
    return <p>Error: {JSON.stringify(error)}</p>;
  }

  if (isLoading || !organization?.id) {
    return <p>Loading...</p>;
  }

  if (isSuccess)
    return (
      <>
        <div className="table react-table report-table">
          <div className="action-panel">
            <Row className="mx-0">
              <Col>
                <FullCycleAndBreakageFilter
                  filter={filter}
                  setFilter={setFilter}
                  useFilter={useFilter}
                  setUseFilter={setUseFilter}
                  exportData={exportData}
                  exportLink={exportLink}
                  exportHeaders={exportHeaders}
                  download={download}
                />
              </Col>
            </Row>
          </div>
          {isLoading && <p>Loading...</p>}
          {isSuccess && (
            <>
              <h4>Annual Breakage Report</h4>
              <table className="table table-striped report-table">
                <thead>
                  <tr>
                    {columns.map((column, i) => {
                      return (
                        <React.Fragment key={i}>
                          <th width="20%">{column.Header}</th>
                        </React.Fragment>
                      );
                    })}
                  </tr>
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, index) => {
                    prepareRow(row);
                    return (
                      <>
                        <tr {...row.getRowProps()} key={index}>
                          {row.cells.map((cell, i) => {
                            return (
                              <td {...cell.getCellProps()} key={i}>
                                {cell.column?.Header === "Year" ? (
                                  <span>{filter.year}</span>
                                ) : (
                                  <span>{cell.render("Cell")}</span>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
              <h4 className="mt-5">Quarterly Breakage Report</h4>
              <table className="table table-striped report-table">
                <thead>
                  <tr>
                    {columns.map((column, i) => {
                      return (
                        <React.Fragment key={i}>
                          <th width="20%">
                            {column.Header === "Year"
                              ? "Year-Quarter"
                              : column.Header}
                          </th>
                        </React.Fragment>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data?.results?.quarterly?.["Q1"]?.map((q1) => (
                    <tr className="odd">
                      <td width="20%">{q1.name}</td>
                      <td width="20%">{filter.year} - Q1</td>
                      <td width="20%">${q1.awarded_points}</td>
                      <td width="20%">${q1.expired_points}</td>
                      <td width="20%">${q1.deactivated_points}</td>
                      <td width="20%">${q1.total_expired_points}</td>
                      <td width="20%">{q1.percent_expirations}%</td>
                    </tr>
                  ))}
                  {data?.results?.quarterly?.["Q2"]?.map((q2) => (
                    <tr className="odd">
                      <td width="20%">{q2.name}</td>
                      <td width="20%">{filter.year} - Q2</td>
                      <td width="20%">${q2.awarded_points}</td>
                      <td width="20%">${q2.expired_points}</td>
                      <td width="20%">${q2.deactivated_points}</td>
                      <td width="20%">${q2.total_expired_points}</td>
                      <td width="20%">{q2.percent_expirations}%</td>
                    </tr>
                  ))}
                  {data?.results?.quarterly?.["Q3"]?.map((q3) => (
                    <tr className="odd">
                      <td width="20%">{q3.name}</td>
                      <td width="20%">{filter.year} - Q3</td>
                      <td width="20%">${q3.awarded_points}</td>
                      <td width="20%">${q3.expired_points}</td>
                      <td width="20%">${q3.deactivated_points}</td>
                      <td width="20%">${q3.total_expired_points}</td>
                      <td width="20%">{q3.percent_expirations}%</td>
                    </tr>
                  ))}
                  {data?.results?.quarterly?.["Q4"]?.map((q4) => (
                    <tr className="odd">
                      <td width="20%">{q4.name}</td>
                      <td width="20%">{filter.year} - Q4</td>
                      <td width="20%">${q4.awarded_points}</td>
                      <td width="20%">${q4.expired_points}</td>
                      <td width="20%">${q4.deactivated_points}</td>
                      <td width="20%">${q4.total_expired_points}</td>
                      <td width="20%">{q4.percent_expirations}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </>
    );
};

const TableWrapper = ({ organization, programs }) => {
  if (!organization || !programs) return "Loading...";
  return (
    <QueryClientProvider client={queryClient}>
      <DataTable organization={organization} programs={programs} />
    </QueryClientProvider>
  );
};

export default withRouter(
  connect((state) => ({
    organization: state.organization,
  }))(TableWrapper)
);
