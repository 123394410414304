import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import AwardDetailCard from './components/AwardDetailIndex.jsx';

const AwardDetail = () => {
  return (
    <Container className="dashboard">
      <Row>
        <AwardDetailCard />
      </Row>
    </Container>
)}

export default AwardDetail;
