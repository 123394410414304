import React from "react";


export const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;
  
      React.useEffect(() => {
        if (resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate ?? false;
        }
      }, [resolvedRef, indeterminate]);
  
      return <input type="checkbox" ref={resolvedRef} {...rest} />;
    }
  );